import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Caytan Varengard`}</strong>{` is a member of the `}<a parentName="p" {...{
        "href": "/Varengard%20Family",
        "title": "Varengard Family"
      }}>{`Varengard Family`}</a>{` and talented `}<a parentName="p" {...{
        "href": "/Vastcaller",
        "title": "Vastcaller"
      }}>{`Vastcaller`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      